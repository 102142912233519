<!--modal popup start-->
<ng-template  #findtest let-modal>
    <div class="modal-header px-4">
        <h3 class="modal-title" id="myModalLabel1"> Find Tests</h3>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body bg-light px-5">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12" (click)="modal.dismiss('Cross click')">
                    <app-main-categories></app-main-categories>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->