<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <li *ngFor="let category of categories; trackBy: trackByFn">
                    <a [routerLink]="['/shop/categories/', category.slug]" class="fw-bolder">
                        {{ category.name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- End Categories List -->