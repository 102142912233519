import { Component, OnDestroy, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
   selector: 'app-categories',
   templateUrl: './categories.component.html',
   styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {

   public categories: any[] = [];
   public collapse: boolean = true;
   private _unsubscribeAll: Subject<any> = new Subject<any>();

   constructor(public _productService: ProductService) {
      this._productService.categories$
         .pipe(takeUntil(this._unsubscribeAll))
         .subscribe((categories: any[]) => {
            this.categories = categories;
         });
   }

   ngOnInit(): void {
   }

   ngOnDestroy(): void {
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
   }

   get filterByCategory() {
      const category = [...new Set(this.categories.map(product => product.name))]
      return category
   }

}
