import { Component, OnInit, OnDestroy } from '@angular/core';
import { Product } from '../../classes/product';
import { Subject, takeUntil } from 'rxjs';
import { ProductService } from '../../services/product.service';

@Component({
   selector: 'app-main-categories',
   templateUrl: './main-categories.component.html',
   styleUrls: ['./main-categories.component.scss']
})
export class MainCategoriesComponent implements OnInit, OnDestroy {

   public categories: Product[] = [];
   public collapse: boolean = true;
   private _unsubscribeAll: Subject<any> = new Subject<any>();

   constructor(private _productService: ProductService) {
      this._productService.categories$
         .pipe(takeUntil(this._unsubscribeAll))
         .subscribe((categories: any[]) => {
            this.categories = categories;
         });
   }

   ngOnInit(): void { }

   ngOnDestroy(): void {
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
   }

   // get filterbyCategory() {
   //   const category = [...new Set(this.products.map(product => product.type))]
   //   return category
   // }

   trackByFn(index: number, item: any): any
   {
       return item.id || index;
   }

}
