import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
   selector: 'app-footer-one',
   templateUrl: './footer-one.component.html',
   styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit, OnDestroy {

   @Input() class: string = 'footer-light' // Default class 
   @Input() themeLogo: string = 'assets/images/logos/logo.webp' // Default Logo
   @Input() newsletter: boolean = true; // Default True
   public cats1: any[] = [];
   public cats2: any[] = [];
   private _unsubscribeAll: Subject<any> = new Subject<any>();

   public today: number = Date.now();

   constructor(public _productService: ProductService) {
      this._productService.categories$
         .pipe(takeUntil(this._unsubscribeAll))
         .subscribe((categories: any[]) => {
            const data = categories;
            this.cats1 = data.slice(0, Math.floor(data.length / 2));
            this.cats2 = data.slice(Math.floor(data.length / 2));
         });
   }

   ngOnInit(): void {
   }

   ngOnDestroy(): void {
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
   }

}
