import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'discount'
})
export class DiscountPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        const price = args.discount
            ? args.discount_type === "flat"
                ? args.unit_price - args.discount
                : args.unit_price - (args.unit_price * args.discount / 100)
            : args.unit_price;
        return price
    }

}
