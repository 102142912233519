import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Subject, takeUntil } from 'rxjs';
import { FindeTestComponent } from '../../components/modal/find-test/find-test.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FindComponent } from '../../components/modal/find/find.component';

@Component({
   selector: 'app-footer-one',
   templateUrl: './footer-one.component.html',
   styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit, OnDestroy {
   // @ViewChild("fintTest") FindTest: FindeTestComponent;
   @ViewChild(FindeTestComponent) FindTest: FindeTestComponent;

   @Input() class: string = 'footer-light' // Default class 
   @Input() themeLogo: string = 'assets/images/logos/logo.webp' // Default Logo
   @Input() newsletter: boolean = true; // Default True
   public cats1: any[] = [];
   public cats2: any[] = [];
   private _unsubscribeAll: Subject<any> = new Subject<any>();

   public today: number = Date.now();

   constructor(public _productService: ProductService,
      private modalService: NgbModal,
      
   ) {
      this._productService.categories$
         .pipe(takeUntil(this._unsubscribeAll))
         .subscribe((categories: any[]) => {
            const data = categories;
            this.cats1 = data.slice(0, Math.floor(data.length / 2));
            this.cats2 = data.slice(Math.floor(data.length / 2));
         });
   }

   ngOnInit(): void {
   }

   findModal() {
      // this.FindTest.openModal()
      const modalRef = this.modalService.open(FindComponent, {
         size: 'lg' // size: 'xs' | 'sm' | 'lg' | 'xl'
      });

   }

   openFindTestModal() {
      if (this.FindTest) {
        this.FindTest.openModal();
      }
    }

   

   ngOnDestroy(): void {
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
   }

}
