import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { AuthService } from './auth.service';

export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
   const authService = inject(AuthService);

   let newReq = req.clone();

   const token = authService.getToken();

   if (token) {
      newReq = req.clone({
         headers: req.headers.set('Authorization', 'Bearer ' + token),
      });
   }

   return next(newReq).pipe(
      catchError((error) => {
         if (error instanceof HttpErrorResponse && error.status === 401) {

            authService.logout();

            location.reload();
         }

         return throwError(() => error);
      }),
   );
};
