import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root'
})
export class ApiService {
   private readonly baseUrl: string = environment.apiUrl; // Assuming API_URL is defined elsewhere

   constructor(private http: HttpClient) { }

   get<T>(path: string, options?: any): Observable<T> {
      return this.http.get<T>(this.baseUrl + path, options)
         .pipe(
            map(response => response as T),
            catchError(this.handleError)
         );
   }

   post<T>(path: string, body: any, options?: any): Observable<T> {
      return this.http.post<T>(this.baseUrl + path, body, options)
         .pipe(
            map(response => response as T),
            catchError(this.handleError)
         );
   }

   put<T>(path: string, body: any, options?: any): Observable<T> {
      return this.http.put<T>(this.baseUrl + path, body, options)
         .pipe(
            map(response => response as T),
            catchError(this.handleError)
         );
   }

   patch<T>(path: string, body: any, options?: any): Observable<T> {
      return this.http.patch<T>(this.baseUrl + path, body, options)
         .pipe(
            map(response => response as T),
            catchError(this.handleError)
         );
   }

   delete<T>(path: string, options?: any): Observable<T> {
      return this.http.delete<T>(this.baseUrl + path, options)
         .pipe(
            map(response => response as T),
            catchError(this.handleError)
         );
   }

   // Error handling function
   private handleError(error: any) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Client-side or network error occurred. Handle it accordingly.
         errorMessage = `Error: ${error.error.message}`;
      } else {
         // The backend returned an unsuccessful response code.
         // The response body may contain clues as to what went wrong.
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.error(errorMessage); // Log the error to the console
      return throwError(() => Error(errorMessage)); // Rethrow the error as an observable
   }
}