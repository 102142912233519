import { Component, OnInit, ViewChild } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { FindeTestComponent } from '../modal/find-test/find-test.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FindComponent } from '../modal/find/find.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];
  @ViewChild("fintTest") FindTest: FindeTestComponent;
  // @ViewChild(FindeTestComponent, { static: false }) FindTest: FindeTestComponent;


  constructor(private router: Router, public navServices: NavService,
    private modalService: NgbModal,
  ) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  findModal() {
    console.log('ddddd');
    // this.FindTest.openModal()
    const modalRef = this.modalService.open(FindComponent, {
      size: 'lg' // size: 'xs' | 'sm' | 'lg' | 'xl'
  });

  }
  

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
