<!--footer section -->
<footer [class]="class">
	<div class="light-layout red-bg " *ngIf="newsletter">
		<div class="container-fluid">
			<div class=" border-section border-top-0  border-bottom-0">
				<div class="row pt-4  pb-3">
				 <div class="col-lg-4">
						<div class="subscribe mx-auto">
							<div class="mx-auto">
								<h4 class="text-light">Find a Test</h4>
							</div>
						</div>
					</div> 
				 <div class="col-lg-4">
						<div class="subscribe">
							<div class="mx-auto">
								<h4 class="text-light footer-link-hover" href="/find-lab">Find a Lab</h4>
							</div>
						</div>	
					</div> 
					 <div class="col-lg-4">
						<div class="subscribe border-right-0 no-border">
							<div class="mx-auto">
								<h4 class="text-light">Call Us</h4>
							</div>
						</div>
					</div>
			
				</div>
			</div>
		</div>
	</div>
	<section class="section-b-space" [ngStyle]="{'background-image': 'url(assets/images/footerbg.webp)'}">
	
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo" class="footer-logo"> 
					
						</div>
						<p> <b>Horizon Online lab testing makes it super simple, quick, and affordable for you to submit an online test request for the laboratory test you want.</b> 
						</p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a ><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a ><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a ><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a ><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a ><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul>

						
                        </div>
						<h6 class="mt-4">* Some test results may take more than 3 business days.</h6>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4 class="text-color">Quick Links</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a routerLink="/">Home</a></li>
                                <li><a >Finds Test</a></li>
                                <li><a routerLink="/pages/how-it-works">How it Works</a></li>
                                <li><a routerLink="/pages/faq">FAQS</a></li>
								<li><a routerLink="/pages/contact">contact Us</a></li>
								<li><a routerLink="/pages/dashboard">My Account</a></li>
								<li><a routerLink="/pages/privacy-policy">Privacy Policy</a></li>
								<li><a [routerLink]="'/pages/terms-and-conditions'" >Terms & Conditions</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4 class="text-color">Test Categories</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
								<li *ngFor="let category of cats1">
									<a [routerLink]="['/shop/categories/', category.slug]" class="fw-bolder">
										{{ category.name }}
									</a>
								</li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4 class="text-color">Test Categories</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li *ngFor="let category of cats2">
									<a [routerLink]="['/shop/categories/', category.slug]" class="fw-bolder">
										{{ category.name }}
									</a>
								</li>
                            </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer text-center">
		<div class="container">
			<div class="row">
                <div class="col-xl col-md col-sm">
                  <div class="footer-end ">
                    <p class="fw-bold"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}}  Horizon Online Lab. All rights reserved.</p>
                  </div>
                </div>
                <!-- <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div> -->
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->



