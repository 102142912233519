<div *ngIf="!loader" class="product-detail">
  <div class="product-content">
    <div class="row align-items-center justify-between">
      <div class="col-lg-12">
        <a [routerLink]="productRouteUrl">
          <h4 class="fw-bold mb-4 mt-3">{{ product?.name }}</h4>
        </a>
        <p [innerHTML]="product.short_description" class="product-description"></p>
      </div>
    </div>
  </div>
  
  <div class="product-bottom">
    <h4>
      <div class="fs-4">
        {{ product?.unit_price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount">
          <span class="money">{{ product?.unit_price * currency?.price | currency:currency?.currency:'symbol' }}</span>
        </del>
      </div>
    </h4>
    <div class="btn btn-solid btn-block mt-2 view-btn">
      <div [routerLink]="productRouteUrl">
        <i class="ti-eye"></i> View
      </div>
    </div>
  </div>
</div>



<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<!-- <app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view> -->
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
