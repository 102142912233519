// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://account.horizononlinelab.com/api/v1/',
    //   apiUrl:'http://127.0.0.1:8010/api/v1/',
    auth: {
        grant_type: 'password',
        client_id: '9b7608a8-b4da-44a7-916f-1a3142c9e4fa',
        client_secret: 'eGIavaJCbHK8zkJtFV9dVd6WfmhKfdHSAcJw4qTy',
        scope: 'manage-account'
    },
    googleMapsApiKey: 'AIzaSyBif9bCI7AyENJGlmWNy48m3Ld1GNiiEto',
    stripe_token: 'pk_live_51KJ9VHCLq5oUqFtsWRllO4Bsw9rPzm7cfu4OUBK7ljaRFedo0qg8d5VOLbwbNTqTr91srox727o2S1Z4gytbLDaR00l4VVwxmf',
};
