<div *ngIf="!loader">
  <div class="product-detail w-100 ">
    <div class="row">
      <div class="col-lg-9">
        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
        <a [routerLink]="['/shop/product/left/sidebar/', product?.slug]">
          <h6>{{ product?.name | titlecase }}</h6>
        </a>
        <p [innerHTML]="product.details"></p>
      </div>

      <div class="col-3 ">
        <h4>
          <div class="fs-4 ">
            {{ product?.unit_price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
            <del *ngIf="product?.discount"><span class="money"> {{ product?.unit_price * currency?.price |
                currency:currency?.currency:'symbol' }}</span></del>
          </div>
        </h4>
        <div class=" btn btn-solid btn-sm1 mt-2">
          <!-- <a href="javascript:void(0)" class="text-light" title="Add to cart" (click)="CartModal.openModal(product)" -->
          <a href="javascript:void(0)" class="text-light" title="Add to cart" (click)="addToCart(product)"
            *ngIf="cartModal">
            <i class="ti-shopping-cart"></i> add to cart
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<!-- <app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view> -->
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
