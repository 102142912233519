import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, startWith, delay, tap, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Category, Product } from '../classes/product';
import { ApiService } from 'src/app/core/service/api.service';



@Injectable({
   providedIn: 'root'
})
export class AccountService {



   private _orders: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
   private _order: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
   private _categories: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
   private _self: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
   private _category: BehaviorSubject<any | null> = new BehaviorSubject(null);
   private _address: BehaviorSubject<any | null> = new BehaviorSubject(null);
   private _guestId: BehaviorSubject<any | null> = new BehaviorSubject(null);
   constructor(private http: HttpClient,
      private toastrService: ToastrService, private _apiService: ApiService) { }

   /*
     ---------------------------------------------
     ---------------  Product  -------------------
     ---------------------------------------------
   */

   get categories$(): Observable<any[]> {
      return this._categories.asObservable();
   }

   get category$(): Observable<any[]> {
      return this._category.asObservable();
   }
   get orders$(): Observable<any[]> {
      return this._orders.asObservable();
   }

   get order$(): Observable<any[]> {
      return this._order.asObservable();
   }
   get address$(): Observable<any[]> {
      return this._address.asObservable();
   }

   get self$(): Observable<any[]> {
      return this._self.asObservable();
   }

   get guestId$(): Observable<any[]> {
      return this._guestId.asObservable();
  }

   self(): Observable<any> {
      return this._apiService.get<any>(`customer/info`).pipe(
         tap((response: any) => {
            localStorage.setItem('user', JSON.stringify(response));
            this._self.next(response);
         })
      );
   }



    placeOrder(data: any): Observable<any> {
      // Convert the data object into query parameters
      const params = new HttpParams({ fromObject: data });

      // Use a GET request with query parameters
      return this._apiService.get(`customer/order/place`, { params });
    }


   getGuestId(): Observable<any> {
      const guestId = localStorage.getItem('guest_id');
      console.log('guestId', guestId);

      if (guestId) {
          // If guest_id exists in localStorage, update _guestId BehaviorSubject and return an Observable of it.
          this._guestId.next({ guest_id: Number(guestId) });
          return of({ guest_id: Number(guestId) });
      } else {
          // If guest_id doesn't exist, call the API, store in localStorage, and update _guestId.
          return this._apiService.get<any>('get-guest-id').pipe(
              tap((response: any) => {
                  localStorage.setItem('guest_id', response.guest_id); // Save to localStorage
                  this._guestId.next(response);                        // Update BehaviorSubject
              })
          );
      }
  }

   updateProfile(profileData: any): Observable<any> {
      return this._apiService.put<any>(`customer/update-profile`, profileData);
    }


    getAddressList(): Observable<any> {
      return this._apiService.get<any>(`customer/address/list`).pipe(
         tap((response: any) => {
            this._address.next(response);
         })
      );
   }

    addNewAddress(addressData: any): Observable<any> {
      return this._apiService.post<any>(`customer/address/add`, addressData);
    }

    updateAddress(id: number, addressData: any): Observable<any> {
      return this._apiService.post<any>(`customer/address/update`, { id, ...addressData });
    }

    deleteAddress(id: number): Observable<any> {
      return this._apiService.post<any>(`customer/address/delete`, { address_id: id });
    }



   getOrders(): Observable<any[]> {
      return this._apiService.get<any[]>('customer/order/list').pipe(
         tap((response: any) => {
            this._orders.next(response);
         }),
      );
   }

   getOrderDetails(orderId: number, isGuest: boolean, customerId: number, guestId?: number): Observable<any> {
      // Construct HTTP query parameters
      let params = new HttpParams()
        .set('order_id', orderId.toString())
        .set('customer_id', customerId.toString())
        .set('is_guest', isGuest ? '1' : '0');

      if (isGuest && guestId) {
        params = params.set('guest_id', guestId.toString());
      }

      // Make the GET request
        return this._apiService.get<any>(`customer/order/details`, { params });
    }

   getOrderById(id: string): Observable<any> {
      return this._apiService.get<any>(`customer/order/details?order_id=${id}`).pipe(
         tap((response: any) => {
            this._order.next(response);
         }),
      );
   }

   downloadRequestFile(id: string) {
    return this._apiService.get(`customer/order/download-request-file?order_id=${id}`, { responseType: 'blob' });
   }

   downloadTestResult(id: string) {
    return this._apiService.get(`customer/order/download-test-result?order_id=${id}`, { responseType: 'blob' });
   }

   getCategories(): Observable<any[]> {
      return this._apiService.get<any[]>('categories').pipe(
         tap((response: any) => {
            this._categories.next(response);
         }),
      );
   }

   getCategoryById(id: string): Observable<any> {
      return this._apiService.get<any>(`categories/${id}`).pipe(
         map((category) => {
            this._category.next(category);

            return category;
         }),
         switchMap((category) => {
            if (!category) {
               return throwError(() => 'Could not found category with id of ' + id + '!');
            }

            return of(category);
         }),
      );
   }


   // Get category by slug
   public getCategoryBySlug(slug: string): Observable<Category> {
      return this._categories.pipe(
         map(categories => categories.find(category => category.slug === slug))
      );
   }


}
