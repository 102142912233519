<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1"> Problem List</h4>
  <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" rippleEffect>
    Save </button>
</div>


<!--modal popup end-->